import React, { memo } from 'react'
import { ConfigProvider } from 'antd'
import {IntlProvider} from 'react-intl'

import AppLocale from '../../lngProvider'
import MainApp from './MainApp'
import { useAppSelector } from '../../store/hooks'
import { selectLocaleSlice } from '../../store/LocaleSlice'

import { availableLanguages } from '@vacationtracker/shared/i18n'

const App = (props) => {
  const { locale } = useAppSelector(selectLocaleSlice)
  const safeLocale = locale?.locale || availableLanguages.en

  const currentAppLocale = AppLocale[safeLocale]

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>
        <MainApp {...props}/>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default memo(App)
