import React, { Component } from 'react'
import ReactPlaceholder from 'react-placeholder'
import CircularProgress from '../components/circular-progress'

import 'react-placeholder/lib/reactPlaceholder.css'

export default function asyncComponent(importComponent) {
  class AsyncFunc extends Component<{}, { component: any }> {
    public mounted = false
    constructor(props) {
      super(props)
      this.state = {
        component: null,
      }
    }

    componentWillUnmount() {
      this.mounted = false
    }

    async componentDidMount() {
      this.mounted = true
      // eslint-disable-next-line
      const { default: Component } = await importComponent()
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />,
        })
      }
    }

    render() {
      // eslint-disable-next-line
      const Component = this.state.component || <CircularProgress />
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      )
    }
  }

  return AsyncFunc
}
