import React, { useEffect } from 'react'
import { GraphQLClient, ClientContext } from 'graphql-hooks'
import './styles/theme.less'
import './assets/vendors/style'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { Amplify, Auth } from 'aws-amplify'
import * as microsoftTeams from '@microsoft/teams-js'

import App from './containers/App/index'
import { selectThemeSlice, setTheme } from './store/ThemeSlice'
import { MicrosoftTeams } from './services/auth/microsoft/microsoft'
import { ThemeType } from './constants/ThemeSetting'
import { useAppDispatch, useAppSelector } from './store/hooks'

Amplify.configure({
  API: {
    // eslint-disable-next-line
    graphql_headers: async () => {
      const session = await Auth.currentSession()
      return {
        Authorization: session.getIdToken().getJwtToken(),
      }
    },
    endpoints: [
      {
        name: 'CoreEvent',
        endpoint: process.env.REACT_APP_API_URL,
        // eslint-disable-next-line
        custom_header: async () => {
          const session = await Auth.currentSession()
          return {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
          }
        },
      },
    ],
  },
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    mandatorySignIn: true,
  },
  // eslint-disable-next-line
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  // eslint-disable-next-line
  aws_appsync_region: process.env.REACT_APP_REGION,
  // eslint-disable-next-line
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
})

const GraphQlApiUrl = process.env.REACT_APP_GRAPHQL_ENDPOINT || ''

const graphQlClient = new GraphQLClient({
  url: GraphQlApiUrl,
})

const MainApp = () => {
  const msAuth = new MicrosoftTeams()
  const dispatch = useAppDispatch()
  const { theme } = useAppSelector(selectThemeSlice)

  useEffect(() => {
    msAuth.getContext().then(msContext => {
      msContext.app.theme && handleThemeChange(msContext.app.theme)
    })
    microsoftTeams.app.registerOnThemeChangeHandler(handleThemeChange)
  }, [theme])

  const handleThemeChange = (theme) => {
    dispatch(setTheme(theme as ThemeType))
  }
  return (<>
    <ClientContext.Provider value={graphQlClient}>
      <BrowserRouter>
        <Switch>
          <Route path="/" render={(routerProps) => <App {...routerProps} />} />
        </Switch>
      </BrowserRouter>
    </ClientContext.Provider>
  </>)
}
export default MainApp
